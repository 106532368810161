<template>
  <div>
    <div v-if="isBreachesLoaded && passworized">
      <b-row class="mb-3">
        <b-col xl="4" md="6" sm="12">
          <div class="card card-custom bg-light-danger card-stretch gutter-b">
            <!--begin::Body-->
            <div class="card-body my-3">
              <span
                href="#"
                class="card-title font-weight-bolder text-danger text-hover-state-dark font-size-h6 mb-4 d-block"
              >
                {{ $t("breaches.boxes.passwords") }}
              </span>
              <div class="font-weight-bold text-muted font-size-sm">
                <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2">
                  {{
                    passworized !== false && passworized.totalbreachespwd > 0
                      ? $n(
                          (passworized.totalbreachespwd -
                            passworized.totalbreachespwdsolved) /
                            passworized.totalbreachespwd,
                          "percent"
                        )
                      : "-"
                  }}
                </span>
                <span
                  v-if="
                    passworized !== false && passworized.totalbreachespwd > 0
                  "
                >
                  {{
                    passworized.totalbreachespwd -
                      passworized.totalbreachespwdsolved
                  }}
                  /
                  {{ passworized.totalbreachespwd }}
                </span>
              </div>
              <b-progress
                max="1"
                :show-progress="false"
                :value="
                  (passworized.totalbreachespwd -
                    passworized.totalbreachespwdsolved) /
                    passworized.totalbreachespwd
                "
                variant="danger"
                class="progress-xs mt-7 bg-danger-o-60"
              />
            </div>
            <!--end:: Body-->
          </div>
        </b-col>
        <b-col xl="4" md="6" sm="12">
          <div class="card card-custom bg-light-warning card-stretch gutter-b">
            <!--begin::Body-->
            <div class="card-body my-3">
              <span
                href="#"
                class="card-title font-weight-bolder text-warning text-hover-state-dark font-size-h6 mb-4 d-block"
              >
                {{ $t("breaches.boxes.unsolved") }}
              </span>
              <div class="font-weight-bold text-muted font-size-sm">
                <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2">
                  {{
                    passworized !== false && passworized.totalbreaches > 0
                      ? $n(
                          (passworized.totalbreaches -
                            passworized.totalbreachessolved) /
                            passworized.totalbreaches,
                          "percent"
                        )
                      : "-"
                  }}
                </span>
                <span
                  v-if="passworized !== false && passworized.totalbreaches > 0"
                >
                  {{
                    passworized.totalbreaches - passworized.totalbreachessolved
                  }}
                  /
                  {{ passworized.totalbreaches }}
                </span>
              </div>
              <b-progress
                max="1"
                :show-progress="false"
                :value="
                  (passworized.totalbreaches -
                    passworized.totalbreachessolved) /
                    passworized.totalbreaches
                "
                variant="warning"
                class="progress-xs mt-7 bg-warning-o-60"
              />
            </div>
            <!--end:: Body-->
          </div>
        </b-col>
        <b-col xl="4" md="6" sm="12">
          <div class="card card-custom bg-light-success card-stretch gutter-b">
            <!--begin::Body-->
            <div class="card-body my-3">
              <span
                href="#"
                class="card-title font-weight-bolder text-success text-hover-state-dark font-size-h6 mb-4 d-block"
              >
                {{ $t("breaches.boxes.solved") }}
              </span>
              <div class="font-weight-bold text-muted font-size-sm">
                <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2">
                  {{
                    passworized !== false && passworized.totalbreaches > 0
                      ? $n(
                          passworized.totalbreachessolved /
                            passworized.totalbreaches,
                          "percent"
                        )
                      : "-"
                  }}
                </span>
                <span
                  v-if="passworized !== false && passworized.totalbreaches > 0"
                >
                  {{ passworized.totalbreachessolved }} /
                  {{ passworized.totalbreaches }}
                </span>
              </div>
              <b-progress
                max="1"
                :show-progress="false"
                :value="
                  passworized.totalbreachessolved / passworized.totalbreaches
                "
                variant="success"
                class="progress-xs mt-7 bg-success-o-60"
              />
            </div>
            <!--end:: Body-->
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5" v-if="isBreachesLoaded">
        <b-col
          xl="4"
          lg="6"
          md="6"
          sm="6"
          v-for="breach in pwned"
          :key="breach.breachId"
        >
          <breach
            :breach="breach.breach"
            :status="breach.stakeholders[0].status"
            :surveyEntityId="breach.stakeholders[0].surveyEntityId"
            :breachId="breach.breachId"
            class="mb-4"
          />
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div class="card card-custom bg-light-primary card-stretch gutter-b">
        <div class="card-body my-3">
          <span
            href="#"
            class="card-title font-weight-bolder text-success text-hover-state-dark font-size-h4 mb-4 d-block"
          >
            {{ $t("breaches.boxes.no_breaches") }}
          </span>
        </div>
      </div>
    </div>
    <button
      v-b-tooltip.tooltip.v-dark.top.noninteractive="$t('breaches.info.title')"
      :class="'chat ' + (hasToBounce ? 'bounce' : 'd-none')"
      @click="callBot({ bot: absBot })"
      v-if="isBreachesLoaded && isBotVisible && absBot"
    >
      <img
        :src="require(`@/assets/images/menu/bots/${absBot.image}`)"
        id="breaches-bot"
      />
    </button>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

import Breach from "@/components/breaches/Breach.component";
import { getHelpersBots } from "@/api/user/traits.api";

export default {
  name: "Breaches",
  components: {
    Breach
  },
  data() {
    return {
      isBotVisible: true
    };
  },
  computed: {
    ...mapGetters("Chatbot", ["bots"]),
    ...mapGetters("Profile", ["pwned", "isBreachesLoaded", "passworized"]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    hasToBounce() {
      return this.absBot;
    },
    absBot() {
      return _.find(this.bots, {
        surveyTypeId: parseInt(this.getConstant("SURVEY_KYMATIO_ABSBOT"))
      });
    }
  },
  methods: {
    ...mapActions("Profile", ["reloadBreaches"]),
    ...mapActions("Chatbot", ["activate", "addNewBot", "callBot"]),
    getAbsBot() {
      let surveyAbsTypeId = parseInt(
        this.getConstant("SURVEY_KYMATIO_BREACH_CORPORATE")
      );
      getHelpersBots([surveyAbsTypeId]).then(resp => {
        this.addNewBot(_.first(resp.data.records));
      });
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.reloadBreaches();
        this.getAbsBot();
      }
    },
    isBreachesLoaded(value) {
      if (this.isConstantsActive && value === false) {
        this.reloadBreaches();
      }
      if (value) {
        setTimeout(() => {
          this.$root.$emit("bv::show::tooltip", "breaches-bot");
        }, 10000);
      }
    },
    absBot(value) {
      if (!value) {
        this.getAbsBot();
      }
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isBreachesLoaded) {
      this.reloadBreaches();
    }

    if (this.isConstantsActive) {
      if (!this.absBot) {
        this.getAbsBot();
      }
    }
  }
};
</script>
