<template>
  <b-card :class="`card-custom gutter-b card-stretch`" no-body>
    <!--begin::Body-->
    <div class="card-body" v-if="breach">
      <!--begin::Info-->
      <div class="d-flex align-items-center">
        <!--begin::Pic-->
        <div class="flex-shrink-0 mr-4 symbol symbol-60">
          <img :src="breach.logoPath" alt="image" class="bg-gray-100" />
        </div>
        <!--end::Pic-->
        <!--begin::Info-->
        <div class="d-flex flex-column mr-auto">
          <!--begin: Title-->
          <div class="d-flex flex-column mr-auto">
            <a
              href="#"
              class="text-dark text-hover-success font-size-h4 font-weight-bolder mb-1 text-left"
              >{{ breach.title }}</a
            >
            <div class="d-flex">
              <div class="d-flex align-items-center pr-5">
                <span class="svg-icon svg-icon-md svg-icon-success pr-1">
                  <simple-svg
                    :src="require(`@/assets/images/icons/clock.svg`)"
                  />
                  <!--end::Svg Icon-->
                </span>
                <span class="text-muted font-weight-bold">{{ date }}</span>
              </div>
            </div>
          </div>
          <!--end::Title-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Info-->
      <!--begin::Description-->
      <div class="mb-7 flex-grow-1 text-left mt-7">
        <label
          v-for="dataClass in breach.dataClasses"
          :key="dataClass"
          :class="
            `label label-inline mr-2 mb-2 font-weight-bolder ${
              dataClass === 'Passwords' ? 'label-light-danger' : 'label-default'
            }`
          "
        >
          {{ dataClass }}
        </label>
      </div>
    </div>
    <!--end::Body-->
    <!--begin::Footer-->
    <div
      class="card-footer d-flex align-items-sm-center flex-wrap flex-column flex-sm-row"
      v-if="breach"
    >
      <div class="d-flex align-items-center" v-if="status.solved">
        <div class="d-flex mr-7">
          <b-button
            variant="light-primary"
            disabled
            class="font-weight-bolder font-size-sm p-2 rounded"
          >
            <span class="svg-icon svg-icon-md svg-icon-success pr-1">
              <simple-svg :src="require(`@/assets/images/icons/lock.svg`)" />
              <!--end::Svg Icon-->
              {{ $t("breaches.solved") }}
            </span>
          </b-button>
        </div>
      </div>
      <div class="d-flex align-items-center" v-else>
        <div class="d-flex mr-7" v-if="breach.domain && breach.domain !== ''">
          <b-button
            variant="light-info"
            :href="'https://' + breach.domain"
            target="_blank"
            class="font-weight-bolder font-size-sm p-2 rounded"
          >
            <span class="svg-icon svg-icon-md svg-icon-info pr-1">
              <simple-svg :src="require(`@/assets/images/icons/earth.svg`)" />
              <!--end::Svg Icon-->
              {{ $t("breaches.goToSite") }}
            </span>
          </b-button>
        </div>
        <div class="d-flex mr-7">
          <b-button
            variant="light-warning"
            class="font-weight-bolder font-size-sm p-2 rounded"
            @click="solveBreach"
          >
            <span class="svg-icon svg-icon-md svg-icon-warning pr-1">
              <simple-svg
                :src="require(`@/assets/images/icons/clipboard-check.svg`)"
              />
              <!--end::Svg Icon-->
              {{ $t("breaches.resolve") }}
            </span>
          </b-button>
        </div>
      </div>
    </div>
    <!--end::Footer-->
  </b-card>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { updateBreachStatus } from "@/api/user/breaches.api";
import { SimpleSVG } from "vue-simple-svg";
import { mapActions } from "vuex";

export default {
  name: "Breach",
  components: {
    "simple-svg": SimpleSVG
  },
  props: {
    breach: {
      type: Object,
      required: true
    },
    status: {
      type: Object,
      required: false
    },
    surveyEntityId: {
      required: true
    },
    breachId: {
      required: true
    }
  },
  computed: {
    date() {
      return moment(this.breach.breachDate).format("LL");
    },
    initial() {
      return this.breach.title.charAt(0).toUpperCase();
    },
    calcClass() {
      if (this.status.solved === true) return "success";
      else if (this.status.password) return "danger";
      else return "warning";
    }
  },
  methods: {
    ...mapActions("Profile", ["resetBreaches"]),
    solveBreach() {
      let hasPassword = _.indexOf(this.breach.dataClasses, "Passwords") >= 0;
      this.$swal({
        html: hasPassword
          ? this.$t("breaches.popup.text.password")
          : this.$t("breaches.popup.text.noPassword"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0BB783",
        confirmButtonText: this.$t("breaches.popup.confirmButton"),
        cancelButtonText: this.$t("breaches.popup.cancelButton")
      }).then(result => {
        if (result.isConfirmed) {
          updateBreachStatus(this.surveyEntityId, this.breachId).then(() => {
            this.resetBreaches();
          });
        }
      });
    }
  }
};
</script>
